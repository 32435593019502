import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SwipeableDrawer,
  TextField,
  Typography
} from '@mui/material';
import { usePropertyServicesMutationMutation } from '../../generated/graphql';
import { useAuth } from '../../store';

import { imageFallback, useServiceAlert } from '../../utils';
import useInfo from '../../view/Dashboard/components/InfoCard';
import Stack from '@mui/material/Stack';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useSpaForm from './spa-form-hook';
import dayjs from 'dayjs';

type DrawerProps = {
  name: string;
  desc: string;
  image: string;
  open: boolean;
  serviceID?: number | null;
  handleOpen: () => void;
  handleClose: () => void;
  bookingUrl: string;
  bookingForm: boolean;
};
function Drawer(props: DrawerProps) {
  const {
    name,
    open,
    desc,
    image,
    serviceID,
    handleOpen,
    handleClose,
    bookingUrl,
    bookingForm
  } = props;
  const [requestService] = usePropertyServicesMutationMutation();
  const { button_bg_color } = useInfo();
  const guest = useAuth((state: any) => state.guest);
  const serviceAlert = useServiceAlert(() => {});
  const clickHandler = async () => {
    if (!serviceID) {
      return;
    }
    try {
      const res = await requestService({
        variables: {
          input: {
            quantity: 1,
            property_id: guest?.property_id,
            room_id: guest?.room_id,
            task_id: serviceID
          }
        }
      });
      if (res.data?.requestService?.code === '200') {
        serviceAlert('Submitted Successfully');
        handleClose();
      } else {
        serviceAlert(
          res.data?.requestService?.message ||
            'Something went wrong! Please contact support'
        );
        handleClose();
      }

      // Success alert
    } catch (error) {
      serviceAlert(`Something went wrong! Please try again`);
      handleClose();
    }
  };
  const {
    onSave,
    register,
    handleSubmit,
    Treatments,
    bookingTime,
    setBookingTime,
    setSelectedTreatment,
    selectedTreatment,
    isDialogOpen,
    setIsDialogOpen
  } = useSpaForm();

  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Box
        sx={{
          pt: 0.5,
          pr: 3,
          pl: 3,
          overflow: 'auto',
          maxHeight: '60vh',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <Box style={{ float: 'right' }}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        {image ? (
          <Box
            alt={name}
            src={image}
            component="img"
            onError={imageFallback}
            sx={{ height: 250, width: '100%', objectFit: 'contain' }}
          />
        ) : (
          <Box
            alt={name}
            src="/assets/no.jpg"
            component="img"
            sx={{
              height: 250,
              width: 200,
              marginLeft: '45%',
              objectFit: 'cover'
            }}
          />
        )}

        <Typography
          variant="h6"
          sx={{ fontSize: theme => theme.spacing(2.5), color: 'text.tile' }}
        >
          {name}
        </Typography>
        {desc?.toString().startsWith('<') ? (
          <div
            contentEditable="false"
            dangerouslySetInnerHTML={{ __html: desc?.toString() || '' }}
          ></div>
        ) : (
          <Typography sx={{ color: 'text.secondary' }}>{desc}</Typography>
        )}
        {serviceID && guest?.room_id && (
          <Button
            onClick={clickHandler}
            sx={{ backgroundColor: button_bg_color, color: 'white', mt: 1 }}
          >
            {name.trim() === 'Late Check-out'
              ? 'Request for Late Check-out'
              : 'Request for Booking'}
          </Button>
        )}
        {bookingUrl && (
          <Button
            target="_blank"
            rel="noopener noreferrer"
            href={bookingUrl}
            sx={{ backgroundColor: button_bg_color, color: 'white', mt: 1 }}
          >
            Book Now
          </Button>
        )}

        {bookingForm && (
          <Button
            onClick={() => {
              setIsDialogOpen(true);
              handleClose();
            }}
            sx={{ backgroundColor: button_bg_color, color: 'white', mt: 1 }}
          >
            Book Now
          </Button>
        )}
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
          <DialogTitle id="alert-dialog-title">{`${name} Booking`}</DialogTitle>
          <Box component={'form'} onSubmit={handleSubmit(onSave)}>
            <DialogContent>
              {/* <FormControl fullWidth>
                <br />
                <Autocomplete
                  value={selectedTreatment}
                  onChange={(event, newValue) => {
                    setSelectedTreatment(newValue?.id);
                  }}
                  id="controllable-states-demo"
                  options={Treatments || []}
                  getOptionLabel={(option) => option.name ? option.name : ""}
                  renderInput={params => (
                    <TextField {...params} label="Service" />
                  )}
                />
              </FormControl> */}
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: 'white', padding: '0 5px' }}
                >
                  Select Service
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={e => setSelectedTreatment(e.target.value)}
                  label="Section"
                  defaultValue={selectedTreatment}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200
                      }
                    }
                  }}
                >
                  {Treatments?.map((treatment, index) => (
                    <MenuItem key={index} value={treatment?.id}>
                      {treatment?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack></Stack>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                {...register('guest_email')}
              />
              <TextField
                autoFocus
                margin="dense"
                id="Phone"
                label="Phone Number"
                type="number"
                fullWidth
                variant="outlined"
                {...register('guest_phone')}
              />
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker
                      label="Select DateTime"
                      value={bookingTime}
                      onChange={newValue => setBookingTime(newValue)}
                      minDate={dayjs()}
                      maxDate={dayjs(`${guest?.guest_departure}`)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
              <Button variant="contained" type="submit" autoFocus>
                Send Booking
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </SwipeableDrawer>
  );
}

export { Drawer };
