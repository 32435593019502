import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { camelCase, startCase, flatten, groupBy } from 'lodash';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Typography
} from '@mui/material';

import {
  Header,
  Slider,
  InfoCard,
  SliderCard,
  FeedbackAndPromotions,
  ServiceAndOrder,
  // QuickServices,
  Itineraries
} from './components';
import { useAuth } from '../../store';
import { useMenuItem } from '../InRoomDining/room-dining-hook';
import {
  usePropertyServicesQuery,
  Layout,
  useGetGuestPromotionsLazyQuery,
  usePropertyDetailsQuery,
  useGetHotlyncServicesQuery
} from '../../generated/graphql';
import {
  SERVICES_ICONS,
  IN_ROOM_DINING_ICONS,
  IN_ROOM_CATEGORY_ICONS,
  SERVICEINFO_ICONS
} from '../../constant/services';
import useInfo from './components/InfoCard';
import useHotelService from '../HotelSer/hotel-ser-hooks';
import { useDomain } from '../../utils';
import { format } from 'date-fns';
import TaskHistoryProvider from '../../context/TaskHistoryContext';
import { BreakfastIcon, EngineeringIcon } from '../../components';
import QuickServiceDialog from '../QuickService';

export const useServiceList = () => {
  const guest = useAuth((state: any) => state.guest);
  const { data, loading } = usePropertyServicesQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      property_id: guest.property_id
    }
  });
  const { data: hotlyncServices, loading: loadingServices } =
    useGetHotlyncServicesQuery({
      fetchPolicy: 'network-only',
      variables: {
        property_id: guest.property_id
      }
    });
    
  const computedData = React.useMemo(() => {
    if (!data) {
      return { servicesList: [], groupedServices: [] };
    }
    const parsedTaskList = data.getServices
      ? JSON.parse((data.getServices as any)?.tasklist) 
      : [];
        const servicesList = Object.keys(parsedTaskList);
    const allServices = flatten(Object.values(parsedTaskList));
 
    return {
      allServices,
      servicesList,
      hotlyncServices: hotlyncServices?.getHotlyncServices,
      loadingServices,
      groupedServices: parsedTaskList
    };
  }, [data, hotlyncServices, loadingServices]);
  return { ...computedData, loading };
};

// export const useOpenServiceList = () => {
//   const guest = useAuth((state: any) => state.guest);
//   const { data, loading } = useGetTaskHistoryQuery({
//     variables: {
//       guestID: guest?.guest_id,
//       propertyID: guest?.property_id,
//       roomID: guest?.room_id,
//     },
//     skip: !guest?.guest_id
//   });
//   return { taskHistory: data?.getTaskHistory || [], loading };
// }

interface DropDownProps {
  options: any[];
  sx?: SxProps<Theme>;
  selectedOption: any;
  onClick: (option: any) => void;
  extractId: (option: any) => string;
  extractLabel: (option: any) => string;
}

export const DropDown = (props: DropDownProps) => {
  const { sx, options, selectedOption, extractLabel, extractId, onClick } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...(sx ? sx : {}) }}>
      <Button
        sx={{
          py: 1.5,
          px: 2.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        fullWidth
        disableRipple
        disableFocusRipple
        size="large"
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            sx={{ ml: 2, fontWeight: 500, color: 'primary.main' }}
          >
            {extractLabel(selectedOption)}
          </Typography>
        </Box>
        {openMenu ? (
          <KeyboardArrowUpIcon sx={{ color: 'text.primary' }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color: 'text.primary' }} />
        )}
      </Button>
      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleClose}>
        {options.map(option => (
          <MenuItem
            key={extractId(option)}
            onClick={() => {
              onClick(option);
              handleClose();
            }}
          >
            {extractLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    loading: menuDataLoading,
    Categories,
    Sections,
    data: menuData
  } = useMenuItem();

  function detectOS() {
    const userAgent = window.navigator.userAgent;
    let os = 'Unknown';
  
    if (userAgent.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      os = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      os = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
      os = 'Android';
    } else if (userAgent.indexOf('iOS') !== -1) {
      os = 'iOS';
    }
  
    return os;
  }

  // For Promotions
  const [promotionsQuery, { data: promotions }] =
    useGetGuestPromotionsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails } = usePropertyDetailsQuery({
    variables: {
      domain: domainId as string
    }
  });
  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    promotionsQuery({
      variables: {
        propertyID,
        currDate: format(new Date(), 'yyyy-MM-dd')
      }
    });
  }, [propertyDetails?.getPropertyByDomain?.id, promotionsQuery]);

  const {
    hotlyncServices,
    loadingServices,
    loading: serviceListLoading
  } = useServiceList();
  const { hotelService, diningType } = useHotelService();
  const { layout,bgImage,bgOpacity} = useInfo();
  const [sortedLayout, setSortedLayout] = React.useState<Layout[]>([]);

  const inRoomDiningList = React.useMemo(() => {
    if (!menuData) return [];
    return Object.keys(
      groupBy(menuData.getGuestEdibles, (data: any) => data.type)
    );
  }, [menuData]);

  const inRoomDiningListSection = React.useMemo(() => {
    if (!menuData) return [];
    return Object.keys(
      groupBy(
        menuData?.getGuestEdibles?.flatMap(item => item?.section),
        section => section
      )
    );
  }, [menuData]);

  React.useMemo(() => {
    const orderedLayout: Layout[] = [];
    if (!layout) {
      const default_layout = [
        {
          cardName: 'InfoCard',
          sequence: 0,
          active: true
        },
        {
          cardName: 'ServiceAndOrder',
          sequence: 1,
          active: true
        },
        {
          cardName: 'QuickServices',
          sequence: 2,
          active: true
        },
        {
          cardName: 'InRoomDining',
          sequence: 4,
          active: true
        },
        {
          cardName: 'FeedbackAndPromotions',
          sequence: 6,
          active: true
        },
        {
          cardName: 'ServiceInformation',
          sequence: 3,
          active: true
        },
        {
          cardName: 'Itineraries',
          sequence: 4,
          active: true
        }
      ];

      setSortedLayout(default_layout);

      return;
    }
    for (const card of layout) {
      if (card) {
        orderedLayout[card?.sequence] = card;
      }
    }
    setSortedLayout(orderedLayout);
  }, [layout]);
  function customConversion(inputString:any) {
    const parts = inputString.split('_');
    const convertedParts = parts.map((part:any) => {
      if (part.length > 2) {
        return startCase(part.toLowerCase());
      }
      return part.toUpperCase();
    });
    return convertedParts.join(' ');
  }
  const componentObject: { [key: string]: JSX.Element } = {
    InfoCard: <InfoCard />,
    ServiceAndOrder: <ServiceAndOrder sx={{ mt: 3 }} />,
    Services: (
      <Slider title="Services" loading={loadingServices}>
        {[...(hotlyncServices || [])]?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0)).map((service, index) => {
          const icon = (SERVICES_ICONS as any)[service?.icon as string]|| <EngineeringIcon />;
          return (
            <SliderCard
              key={service?.name}
              icon={service?.uploaded_icon||icon}
              gettitle={customConversion(service?.name)}
              index={index}
              link={`services?service=${customConversion(service?.name)}`}
            />
          );
        })}
      </Slider>
    ),
    InRoomDining: (
      <Slider
        title="In Room Dining"
        loading={menuDataLoading}
        onTitleClick={() => navigate('in-room-dining')}
      >
        {diningType === 'Category'
          ? [...(Categories || [])]
          .filter(category => category?.name !== 'Uncategorized')
              ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
              ?.map((category, index) => {
                const icon = (IN_ROOM_CATEGORY_ICONS as any)[
                  category?.icon || ''
                ] || <BreakfastIcon />;
                return (
                  <SliderCard
                    icon={category?.uploaded_icon || icon}
                    index={index}
                    key={category?.id}
                    gettitle={category?.name || ''}
                    link={`in-room-dining?category=${category?.name}`}
                  />
                );
              }) ||
            inRoomDiningList?.map((category, index) => {
              const camelCaseName = startCase(camelCase(category));
              const icon = (IN_ROOM_DINING_ICONS as any)[camelCaseName] || (
                <BreakfastIcon />
              );
              return (
                <SliderCard
                  icon={icon}
                  index={index}
                  key={category}
                  gettitle={camelCaseName}
                  link={`in-room-dining?category=${category}`}
                />
              );
            })
          : [...(Sections || [])]
              ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
              ?.map((section, index) => {
                const icon = (IN_ROOM_CATEGORY_ICONS as any)[
                  section?.icon || ''
                ] || <BreakfastIcon />;
                return (
                  <SliderCard
                    icon={section?.uploaded_icon || icon}
                    index={index}
                    key={section?.id}
                    gettitle={section?.name || ''}
                    link={`in-room-dining?category=${section?.name}`}
                  />
                );
              }) ||
            inRoomDiningListSection?.map((section, index) => {
              const camelCaseName = startCase(camelCase(section));
              const icon = (IN_ROOM_DINING_ICONS as any)[camelCaseName] || (
                <BreakfastIcon />
              );
              return (
                <SliderCard
                  icon={icon}
                  index={index}
                  key={section}
                  gettitle={camelCaseName}
                  link={`in-room-dining?category=${section}`}
                />
              );
            })}
      </Slider>
    ),
    ServiceInformation: (
      <Slider title="Facilities" loading={serviceListLoading}>
        {[...(hotelService || [])]
          ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
          ?.map((service, index) => {
            const icon = (SERVICEINFO_ICONS as any)[
              service?.icon as string
            ] || <EngineeringIcon />;
            return (
              <SliderCard
                key={service?.name}
                icon={service?.uploaded_icon||icon}
                gettitle={service?.name}
                index={index}
                link={
                  service.sub_properties &&
                  service?.sub_properties?.length>0 ?
                  `sub-properties/${service?.id}`:
                  `service-information/${service?.id}`
                }
              />
            );
          })}
          {propertyDetails?.getPropertyByDomain?.id === "VKGUyyDp2EzS2eZILbH4x" && <SliderCard
            key={1}
            icon={"assets/d-icon.png"}
            gettitle={"spa"}
            index={hotelService?.length || 10}
            externalLink={ (() => {
              const os = detectOS()
              if(os==="Android") return "https://play.google.com/store/apps/details?id=com.WriterInformation.dharana&hl=en&gl=US";
              if(os==="iOS") return "https://apps.apple.com/ca/app/dharana/id1484039881"
              else return null;
            })()
            }
          />}
      </Slider>
    ),
    FeedbackAndPromotions: (
      <FeedbackAndPromotions
        hidePromotionTile={!Boolean(promotions?.getGuestPromotions?.length)}
        hideFeedback={false}
      />
    ),
    Itineraries: <Itineraries sx={{ mt: 3 }} />,
    QuickServices: (
      <QuickServiceDialog
        sx={{
          mt: 4,
          display: 'contents',
          height: 'auto',
          width: '100%',
          overflowX: 'scroll',
          overflowY: 'hidden',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      />
    )
  };

  const backgroundStyles = bgImage? {
    background: `linear-gradient(rgba(255,255,255,${1-(bgOpacity||0)}), rgba(255,255,255,${1-(bgOpacity||0)})),url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

  } : {backgroundColor: 'background.light'}

  return (
    <Box
      sx={{
        p: 2,
        flex: 1,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        ...backgroundStyles
      }}
    >
      <Header />
      <TaskHistoryProvider>
      {sortedLayout?.map(card => {
        if (card?.active===true || card?.active===null) {
          return (
            <React.Fragment key={card?.cardName}>
              {componentObject[card?.cardName]}
            </React.Fragment>
          );
        }
        return null; 
      })}
      </TaskHistoryProvider>
    </Box>
  );
};

export { Dashboard };
