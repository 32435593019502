import { Box } from '@mui/material';

import useHotelService from './hotel-ser-hooks';
import { HeaderLayout, Layout } from '../InRoomDining';
import { FullScreenLoader, ServiceCard } from '../../components';
import { useParams, useSearchParams } from 'react-router-dom';
import useSubProperty from '../SubProperty/sub-property-hooks';

const Services = () => {
  const [searchParams] = useSearchParams();
  const SPID = searchParams.get('SPID');
  const { hotelService, loading } = useHotelService();
  const { subProperties, loading: spLoading } = useSubProperty();
  const { id } = useParams();
    
  if (loading || spLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Facilities" />
      <Box
        sx={{
          p: 2,
          pt: 0,
          flex: 1,
          overflow: 'auto',
          background: '#F4F4F4',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {SPID
          ? Array.isArray(subProperties) &&
            subProperties
              ?.find(sp => sp?.id === SPID)?.sub_services
              ?.filter(ss=>hotelService?.find(hs=> hs.id === id)?.sub_services?.some(ess => ss?.name === ess?.name))
              ?.map((hotelService: any) => {
                return (
                  <ServiceCard
                    showInfo={true}
                    key={hotelService?.name}
                    label={hotelService?.name}
                    description={hotelService?.desc}
                    image={hotelService?.img as string}
                    serviceID={hotelService?.service_id}
                    booking={hotelService?.booking_url}
                    bookingForm={hotelService?.booking_form}
                  />
                );
              })
          : Array.isArray(hotelService) &&
            hotelService
              ?.find(service => service.id === id)
              ?.sub_services?.map((hotelService: any) => {
                return (
                  <ServiceCard
                    showInfo={true}
                    key={hotelService?.name}
                    label={hotelService?.name}
                    description={hotelService?.desc}
                    image={hotelService?.img as string}
                    serviceID={hotelService?.service_id}
                    booking={hotelService?.booking_url}
                    bookingForm={hotelService?.booking_form}
                  />
                );
              })}
      </Box>
    </Layout>
  );
};

export default Services;
